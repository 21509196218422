import { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import instaWingLogoWhite from "../assets/logo.png";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsMobile from "../hooks/useIsMobile";
import { Squash as Hamburger } from "hamburger-react";
import { useQueryClient } from "react-query";
import { krwFormat } from "../utils";
import { AccountDB } from "../types";

const Container = styled.div`
  background-color: var(--main-color);
  padding: 0 40px;
  position: relative;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  display: flex;

  @media (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 1280px) {
    align-items: center;
  }
`;
const LogoImage = styled.img`
  width: 110px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;
const MenuContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  padding-left: 30px;
  justify-content: space-between;

  @media (max-width: 1200px) {
    display: none;
  }
`;
const MenuItem = styled(Link)`
  color: white;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 200ms all;
  font-size: 14px;

  &:hover {
    background: var(--main-hover-color);
  }

  &.big {
    font-size: 16px;
  }
  &.add {
    align-items: center;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    font-size: 12px;

    &.row {
      background: #fff;
      color: #333;
      font-size: 14px;

      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
  }
`;
const MenuItemWrapper = styled.div`
  display: flex;

  &.right {
    font-size: 16px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
const AddContainer = styled.div`
  padding: 4px 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 8px;
`;

const DesktopView: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const profileData = (queryClient.getQueryData("profile") ??
    null) as AccountDB | null;

  const isAuthorization = profileData !== null;

  const showPage = useCallback(() => {
    navigate("/order");
    queryClient.setQueryData("profile", {
      point: 0,
    });
  }, [navigate, queryClient]);

  return (
    <MenuContainer>
      <MenuItemWrapper>
        {isAuthorization && (
          <>
            <MenuItem to="/order">주문하기</MenuItem>
            <MenuItem to="/order-history">주문내역</MenuItem>
            {/* <MenuItem to="/order-auto">자동관리</MenuItem> */}
            <MenuItem to="/point">잔액충전</MenuItem>
            <MenuItem to="/link-guide">링크 입력 가이드</MenuItem>
            <MenuItem to="/rank-guide">인기 게시물 가이드</MenuItem>
            {/* <MenuItem to="/qna">자주 묻는 질문</MenuItem> */}
          </>
        )}
      </MenuItemWrapper>
      <MenuItemWrapper>
        {isAuthorization ? (
          <>
            <MenuItem className="big add" to="/point">
              {krwFormat(profileData.point, { useWon: true })}
              <AddContainer>충전</AddContainer>
            </MenuItem>
            <MenuItem className="big" to="/account">
              계정 설정
            </MenuItem>
            <MenuItem className="big" to="/logout">
              로그아웃
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem className="big" to="/login">
              로그인
            </MenuItem>
            <MenuItem className="big" to="/register">
              회원가입
            </MenuItem>
            <MenuItem
              className="big"
              onClick={(e) => {
                e.preventDefault();
                showPage();
              }}
              to="."
            >
              둘러보기
            </MenuItem>
          </>
        )}
      </MenuItemWrapper>
    </MenuContainer>
  );
};

const MobileMenuContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const HideContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 100%;
  background: rgba(0, 0, 0, 0.7);
`;
const MobileView: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const profileData = (queryClient.getQueryData("profile") ??
    null) as AccountDB | null;

  const isAuthorization = profileData !== null;

  const backgroundClickHandler = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        setIsOpen(false);
      }
    },
    []
  );

  const showPage = useCallback(() => {
    navigate("/order");
    queryClient.setQueryData("profile", {
      point: 0,
    });
  }, [navigate, queryClient]);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <MobileMenuContainer>
      <MobileMenuHeader>
        {isAuthorization && (
          <MenuItem className="big add" to="/point">
            {krwFormat(profileData.point, { useWon: true })}
            <AddContainer>충전</AddContainer>
          </MenuItem>
        )}
        <Hamburger
          color="#fff"
          hideOutline
          duration={0.5}
          easing={"ease"}
          size={25}
          toggled={isOpen}
          toggle={setIsOpen}
        />
      </MobileMenuHeader>
      {isOpen && (
        <HideContainer onClick={backgroundClickHandler}>
          <MenuItemWrapper>
            {isAuthorization ? (
              <>
                <MenuItem className="row" to="/order">
                  주문하기
                </MenuItem>
                <MenuItem className="row" to="/order-history">
                  주문내역
                </MenuItem>
                {/* <MenuItem className="row" to="/order-auto">
                  자동관리
                </MenuItem> */}
                <MenuItem className="row" to="/point">
                  잔액충전
                </MenuItem>
                <MenuItem className="row" to="/link-guide">
                  링크 입력 가이드
                </MenuItem>
                <MenuItem className="row" to="/rank-guide">
                  인기 게시물 가이드
                </MenuItem>
                {/* <MenuItem className="row" to="/qna">
                  자주 묻는 질문
                </MenuItem> */}
                <MenuItem className="row" to="/account">
                  계정 설정
                </MenuItem>
                <MenuItem className="row" to="/logout">
                  로그아웃
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem className="row" to="/login">
                  로그인
                </MenuItem>
                <MenuItem className="row" to="/register">
                  회원가입
                </MenuItem>
                <MenuItem
                  className="row"
                  onClick={(e) => {
                    e.preventDefault();
                    showPage();
                  }}
                  to="."
                >
                  둘러보기
                </MenuItem>
              </>
            )}
          </MenuItemWrapper>
        </HideContainer>
      )}
    </MobileMenuContainer>
  );
};
const HeaderPieces: FC = () => {
  const isMobile = useIsMobile();
  const queryClient = useQueryClient();

  const profileData = (queryClient.getQueryData("profile") ??
    null) as AccountDB | null;

  const isAuthorization = profileData !== null;

  return (
    <Container>
      <LogoContainer>
        <Link to={isAuthorization ? "/order" : "/"}>
          <LogoImage src={instaWingLogoWhite} />
        </Link>
      </LogoContainer>
      {isMobile ? <MobileView /> : <DesktopView />}
    </Container>
  );
};

export default HeaderPieces;
