import styled from "styled-components";
import { MainLayout, SubTitle, Title } from "./styles";
import leftIcon from "../../assets/left-icon.png";
import rightIcon from "../../assets/right-icon.png";
import reviewPeople from "../../assets/review_people.png";
import { useState } from "react";
import useIsMobile from "../../hooks/useIsMobile";

const Container = styled.div`
  padding-top: 125px;
  padding-bottom: 187px;
  text-align: center;
  background: #f7f7f7;

  @media (max-width: 1200px) {
    padding-top: 30px;
    padding-bottom: 60px;
  }
`;
const ReviewList = styled.div`
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
`;

const ReviewItem = styled.div`
  width: 415px;
  background: #ffffff;
  border-radius: 17px;
  padding: 60px 20px 68px 43px;
  box-sizing: border-box;
  flex-shrink: 0;

  > div:nth-child(1) {
    display: flex;

    > div:nth-child(1) {
      width: 60px;
      height: 60px;
      background: #f2f2f2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div:nth-child(2) {
      margin-left: 23px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      > div:nth-child(1) {
        font-weight: 700;
        font-size: 23px;
        line-height: 27px;
        color: #828282;
      }

      > div:nth-child(2) {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: #e0e0e0;
      }
    }
  }

  > div:nth-child(2) {
    margin-top: 45px;
    font-weight: 500;
    font-size: 15;
    line-height: 21px;
    text-align: left;
    letter-spacing: -0.03em;
    color: #bdbdbd;
  }

  &.active {
    transition: all 1s;
    box-shadow: 4px 4px 26px 5px rgba(0, 0, 0, 0.05);

    div {
      transition: all 1s;
    }
    > div:nth-child(1) {
      > div:nth-child(1) {
        background: var(--main-color);
      }
    }
    > div:nth-child(2) {
      color: #4f4f4f;
    }
  }

  @media (min-width: 1200px) {
    &.active {
      width: 620px;
      transition: all 1s;
      box-shadow: 4px 4px 26px 5px rgba(0, 0, 0, 0.05);

      div {
        transition: all 1s;
      }
      > div:nth-child(1) {
        > div:nth-child(1) {
          background: var(--main-color);
        }
        > div:nth-child(2) {
          > div:nth-child(1) {
            font-size: 32px;
            line-height: 38px;
            color: #333333;
          }
          > div:nth-child(2) {
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
      > div:nth-child(2) {
        margin-top: 70px;
        font-size: 24px;
        line-height: 33px;
        color: #4f4f4f;
      }
    }
  }

  @media (max-width: 1200px) {
    width: 320px;
    padding: 44px 37px;

    > div:nth-child(1) {
      > div:nth-child(1) {
        width: 43px;
        height: 43px;
        img {
          width: 25px;
          height: 25px;
        }
      }
      > div:nth-child(2) {
        > div:nth-child(1) {
          font-size: 16px;
          line-height: 19px;
        }
        > div:nth-child(2) {
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
    > div:nth-child(2) {
      margin-top: 34px;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;
const ArrowIcon = styled.img`
  position: absolute;
  width: 21px;
  z-index: 1;
  height: 40px;
  top: 50%;

  &:nth-child(1) {
    left: calc(50% - 360px);
  }
  &:nth-child(2) {
    right: calc(50% - 360px);
  }

  @media (max-width: 1200px) {
    width: 10px;
    height: 20px;
    &:nth-child(1) {
      left: 11px;
    }
    &:nth-child(2) {
      right: 11px;
    }
  }
`;

const ReviewSizeWrap = styled.div`
  max-width: 1920px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 85px;
  padding: 0 120px;

  @media (max-width: 1200px) {
    margin-top: 20px;
    padding: 0;
  }
`;
const ReviweTransform = styled.div`
  display: flex;
  justify-content: center;
  transition: transform 1s;
  gap: 0 95px;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 30px;
`;

const reviews = [
  {
    name: "김**",
    date: "2022.02.30",
    desc: "저의 의견을 적극 반영해주시면서 쉽고 편하게 진행해주셨습니다. 애드투데이 덕분에 마케팅이 어렵다는 고정관념이 깨졌네요. 앞으로도 애드투데이 애용할게요. 감사합니다",
  },
  {
    name: "마케터 김**",
    date: "2022.04.21",
    desc: "제이플러스 덕분에 브랜드 가치를 많이 상승시킬 수\n있었습니다. 주변 마케터에게도 소개하고 있습니다.\n늘 빠른 업무 처리에 감사드려요!",
  },
  {
    name: "타투이스트 김**",
    date: "2022.04.28",
    desc: "인스타그램으로 홍보하는 게 어려웠는데 제이플러스 이용하고 나서 도안, 작업물 노출이 이전보다 훨씬 잘 되고 작업률도 올랐습니다! 꾸준히 이용할 것 같아요!",
  },
  {
    name: "1인기업 최**",
    date: "2022.06.21",
    desc: "1인 기업으로 혼자 모든 걸 이뤄내기 힘든 과정을 거치는\n데에 있어서 많은 도움을 받았습니다. 정말 감사드려요!\n제이플러스 품질에 항상 놀랍니다!",
  },
  {
    name: "민**",
    date: "2022.05.07",
    desc: "카페 운영중인데 요즘 인스타홍보가 핫하다고 해서 이용중입니다. 한달째 이용중인데 불편한점두 없고 인스타 보고 찾아주신 손님들도 많이 계셔서 효과 몸소 느끼는 중입니다 ! 자주 이용할게요! 대박나세요!!!",
  },
];

const ReviewSection = () => {
  const [activeIdx, setActiveIdx] = useState(2);

  const prevPage = () => {
    setActiveIdx((prev) => {
      if (prev === 0) return 4;
      return prev - 1;
    });
  };

  const nextPage = () => {
    setActiveIdx((prev) => {
      if (prev === 4) return 0;
      return prev + 1;
    });
  };

  const [px1, px2] = window.innerWidth > 1200 ? [620, 110] : [320, -95];
  const calcIdx = (activeIdx - 2) * -1;

  const isMobile = useIsMobile();

  return (
    <Container>
      <MainLayout>
        <Title>
          실제 고객님들의 <b>생생한 후기</b>
        </Title>
        <SubTitle>
          업계 최저가로 이용자가 24시간 원활한 상담과{isMobile ? "\n" : ""}
          시원한 서비스를 제공받을 수 있습니다.{"\n"}제이플러스로 인스타그램에
          날개를 달아보세요!
        </SubTitle>
      </MainLayout>
      <ReviewSizeWrap>
        <ReviewList>
          <ArrowIcon onClick={prevPage} src={leftIcon} />
          <ArrowIcon onClick={nextPage} src={rightIcon} />
          <ReviweTransform
            style={{
              transform: `translateX(calc(${px1 * calcIdx}px - ${
                px2 * calcIdx
              }px))`,
            }}
          >
            {reviews.map((review, idx) => (
              <ReviewItem
                key={review.name}
                className={idx === activeIdx ? "active" : ""}
              >
                <div>
                  <div>
                    <img src={reviewPeople} />
                  </div>
                  <div>
                    <div>{review.name}</div>
                    <div>{review.date}</div>
                  </div>
                </div>
                <div>{review.desc}</div>
              </ReviewItem>
            ))}
          </ReviweTransform>
        </ReviewList>
      </ReviewSizeWrap>
    </Container>
  );
};

export default ReviewSection;
