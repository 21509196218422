import { ChangeEvent, FC, FormEvent, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormLabel from "../components/FormLabel";
import {
  BorderBox,
  DefaultLayout,
  FormButton,
  FormInput,
} from "../components/styles";
import { ClassName } from "../GlobalStyles";
import useChangePassword from "../hooks/useChangePassword";
import useCreateApiKey from "../hooks/useCreateApiKey";
import { AccountDB } from "../types";
import { checkLength } from "../utils";

const StyledBorderBox = styled(BorderBox)`
  & + & {
    margin-top: 40px;
  }
`;

const ApiDocsTitle = styled.span`
  display: inline-block;
  font-size: 12px;
  margin-left: 5px;
  color: black;
  text-decoration: underline;
`;

const FormInputWrapper = styled.div`
  > * + * {
    margin-top: 15px;
  }
`;

interface FormState {
  currentPassword: string;
  newPassword: string;
  newPasswordRe: string;
}

const AccountPage: FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const profileData = queryClient.getQueryData("profile") as AccountDB;

  const [formState, setFormState] = useState<FormState>({
    currentPassword: "",
    newPassword: "",
    newPasswordRe: "",
  });

  const { mutate: createApiKey } = useCreateApiKey({
    onSuccess: () => {
      queryClient.refetchQueries("profile");
    },
  });

  const { mutate: changePasswordApi, isLoading } = useChangePassword({
    onSuccess: () => {
      navigate("/logout");
    },
    onError: (error) => {
      switch (error?.response?.status) {
        case 409: {
          toast.error("현재 비밀번호가 다릅니다.");
          return;
        }
      }
      toast.error("오류가 발생했습니다.");
    },
  });

  const changeFormState = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) return;
    if (formState.currentPassword.length === 0) {
      toast.error("현재 비밀번호를 입력해 주세요");
      return;
    }
    if (formState.newPassword.length === 0) {
      toast.error("새로운 비밀번호를 입력해주세요");
      return;
    }
    if (formState.newPassword !== formState.newPasswordRe) {
      toast.error("비밀번호 재확인이 다릅니다.");
      return;
    }

    changePasswordApi(formState);
  };

  return (
    <div>
      <DefaultLayout>
        <form onSubmit={submitHandler}>
          <StyledBorderBox className={ClassName.shadow01}>
            <FormInputWrapper>
              <div>
                <FormLabel text="아이디" />
                <FormInput disabled value={profileData.id} />
              </div>
            </FormInputWrapper>
          </StyledBorderBox>
          <StyledBorderBox className={ClassName.shadow01}>
            <FormInputWrapper>
              <div>
                <FormLabel text="현재 비밀번호" />
                <FormInput
                  placeholder="현재 비밀번호"
                  type="password"
                  onChange={changeFormState}
                  name="currentPassword"
                  value={formState.currentPassword}
                />
              </div>
              <div>
                <FormLabel text="새로운 비밀번호" />
                <FormInput
                  placeholder="새로운 비밀번호"
                  type="password"
                  onChange={changeFormState}
                  name="newPassword"
                  value={formState.newPassword}
                />
              </div>
              <div>
                <FormLabel text="새로운 비밀번호 확인" />
                <FormInput
                  placeholder="새로운 비밀번호 확인"
                  type="password"
                  onChange={changeFormState}
                  name="newPasswordRe"
                  value={formState.newPasswordRe}
                />
              </div>
              <FormButton type="submit">비밀번호 변경</FormButton>
            </FormInputWrapper>
          </StyledBorderBox>
          <StyledBorderBox className={ClassName.shadow01}>
            <FormInputWrapper>
              <div>
                <FormLabel
                  text="대행사 메뉴 "
                  rightChildren={
                    <Link to="/api" target="_blank">
                      <ApiDocsTitle>(API Docs)</ApiDocsTitle>
                    </Link>
                  }
                />
                <FormInput disabled value={profileData.apiKey ?? ""} />
              </div>
              <FormButton onClick={createApiKey} type="button">
                API키 갱신
              </FormButton>
            </FormInputWrapper>
          </StyledBorderBox>
        </form>
      </DefaultLayout>
    </div>
  );
};

export default AccountPage;
