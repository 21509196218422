import { FC } from "react";
import { TermContainer, TermContent, TermTitle } from "../../components/styles";
import serviceTerm from "../../terms/service";

const ServiceTermPage: FC = () => {
  return (
    <TermContainer>
      <TermTitle>
        <b>제이플러스 이용 약관</b>
      </TermTitle>
      <TermContent dangerouslySetInnerHTML={{ __html: serviceTerm }} />
    </TermContainer>
  );
};

export default ServiceTermPage;
