import { useState } from "react";
import styled from "styled-components";
import advantage1 from "../../assets/advantage1.png";
import advantage2 from "../../assets/advantage2.png";
import advantage3 from "../../assets/advantage3.png";
import advantage4 from "../../assets/advantage4.png";
import leftIcon from "../../assets/left-icon.png";
import rightIcon from "../../assets/right-icon.png";
import { MainLayout, SubTitle, Title } from "./styles";
import useIsMobile from "../../hooks/useIsMobile";

const Container = styled.div`
  padding-top: 170px;
  padding-bottom: 207px;
  text-align: center;

  @media (max-width: 1280px) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
`;
const AdvantageListWrap = styled.div`
  padding-top: 110px;
  padding-bottom: 20px;
  display: flex;
  gap: 0 38px;

  @media (max-width: 1280px) {
    width: 214px;
    margin: 0 auto;
    padding: 30px 0;
    gap: 0 90px;
    transition: 0.3s transform;
  }
`;
const AdvantageItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--main-color);
  box-shadow: 7px 7px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 55px 28px;
  color: #fff;

  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 151px;
    height: 151px;
    background: linear-gradient(
      140.53deg,
      rgba(255, 255, 255, 0.26) 13.83%,
      rgba(255, 255, 255, 0) 107.08%
    );
    border-radius: 50%;

    img {
      width: 70px;
      height: 70px;
    }
  }

  > div:nth-child(2) {
    margin-top: 33px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  > div:nth-child(3) {
    margin-top: 30px;
    font-weight: 500;
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.03em;
  }

  @media (max-width: 1280px) {
    padding: 34px 24px;
    width: 100%;
    flex-basis: unset;
    flex-shrink: 0;
    box-sizing: border-box;

    > div:nth-child(1) {
      width: 101px;
      height: 101px;
      img {
        width: 48px;
        height: 48px;
      }
    }
    > div:nth-child(2) {
      margin-top: 20px;
      font-size: 16px;
      line-height: 19px;
    }
    > div:nth-child(3) {
      margin-top: 20px;
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

const AdvantageOverflow = styled.div`
  overflow: hidden;
  width: 100%;
`;
const ArrowWrap = styled.div`
  display: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 1.74603px 3.49206px 8.73016px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(50%);

  > img {
    width: 7px;
    height: 14px;
  }

  &:nth-child(1) {
    left: 30px;
  }
  &:nth-child(2) {
    right: 30px;
  }

  @media (max-width: 1200px) {
    display: flex;
    z-index: 2;
  }
`;

const advantages = [
  {
    icon: advantage1,
    title: "모든 주문 자동 처리",
    desc: "결제를 포함한 모든 부분이\n자동으로 처리됩니다. 피드백을\n기다리실 필요가 없습니다.",
  },
  {
    icon: advantage2,
    title: "타사 대비 낮은 가격",
    desc: "타사에 비해 최대 50% 이상\n절감된 가격으로 동일 퀄리티의\n서비스를 이용하실 수 있습니다.",
  },
  {
    icon: advantage3,
    title: "업계 최대 보안 구축",
    desc: "서비스 통신에 보안 알고리즘을\n적용했습니다. 정보가 외부로\n절대 노출되지 않습니다.",
  },
  {
    icon: advantage4,
    title: "연중 무휴 고객 지원",
    desc: "언제나 원하는 채널로 원하시는\n시간에 궁금한 점을 속 시원하게\n해결하실 수 있습니다.",
  },
];

const AdvantageSection = () => {
  const [activeIdx, setActiveIdx] = useState(0);

  const nextPage = () => {
    setActiveIdx((prev) => {
      if (prev === 3) return 0;
      return prev + 1;
    });
  };

  const prevPage = () => {
    setActiveIdx((prev) => {
      if (prev === 0) return 3;
      return prev - 1;
    });
  };

  const isMobile = useIsMobile();

  return (
    <Container>
      <MainLayout>
        <Title>
          제이플러스가 <b>최고의 선택</b>인{isMobile ? "\n" : ""} 네 가지 이유
        </Title>
        <SubTitle>
          마케팅 전문가와 마케팅 프로그램 전문 개발자의 만남으로
          {isMobile ? "\n" : " "}최고의 효율을 제공합니다.
        </SubTitle>
        <AdvantageOverflow>
          <ArrowWrap onClick={prevPage}>
            <img src={leftIcon} />
          </ArrowWrap>
          <ArrowWrap onClick={nextPage}>
            <img src={rightIcon} />
          </ArrowWrap>
          <AdvantageListWrap
            style={{
              transform: `translateX(calc(-${activeIdx * 100}% - ${
                activeIdx * 90
              }px))`,
            }}
          >
            {advantages.map((item) => (
              <AdvantageItem>
                <div>
                  <img src={item.icon} />
                </div>
                <div>{item.title}</div>
                <div>{item.desc}</div>
              </AdvantageItem>
            ))}
          </AdvantageListWrap>
        </AdvantageOverflow>
      </MainLayout>
    </Container>
  );
};

export default AdvantageSection;
