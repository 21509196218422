import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import iconBackground from "../../assets/icon-background.png";
import logo from "../../assets/logo.png";
import { MainLayout } from "./styles";

const Header = styled.div`
  padding: 10px 60px;
  img {
    width: 135px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-weight: 700;
    font-size: 24px;

    line-height: 23px;
    color: #ffffff;
    text-decoration: none;
  }

  a + a {
    margin-left: 40px;
  }

  @media (max-width: 1280px) {
    padding: 10px;

    a + a {
      margin-left: 20px;
    }
  }
`;

const Container = styled.div`
  position: relative;
  background: linear-gradient(127.46deg, #c6dfe3 21.02%, #9ac0c6 79.9%);
`;
const MainContentContainer = styled.div`
  padding-top: 220px;
  padding-bottom: 260px;
  white-space: pre-wrap;
  display: flex;

  align-items: flex-end;
  flex-direction: column;

  @media (max-width: 1280px) {
    padding-top: 360px;
    align-items: center;
    text-align: center;
    padding-bottom: 45px;
  }
`;
const MainContentHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 50px;
  letter-spacing: -1px;
  color: #fff;
  line-height: 1.3;

  @media (max-width: 1280px) {
    font-size: 24px;
    line-height: 33px;
    margin-top: 20px;
  }
`;
const MainContentHeaderDescription = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-top: 42px;
  color: #fff;

  @media (max-width: 1280px) {
    display: none;
  }
`;
const AroundButton = styled.button`
  padding: 14px 59px;
  background: #fff;
  margin-top: 64px;
  border: none;
  border-radius: 8px;
  font-size: 24px;
  color: var(--main-color);
  font-weight: 600;

  @media (max-width: 1280px) {
    margin-top: 40px;
    padding: 12px 50px;
    font-size: 16px;
  }
`;
const WelcomLayout = styled(MainLayout)``;

const FilledBackground = styled.div`
  width: 636px;
  height: 636px;
  position: absolute;
  bottom: 83px;
  left: 0px;
  border-radius: 50%;
  background: #b4d3d7;

  @media (max-width: 1280px) {
    width: 305px;
    height: 305px;
    bottom: none;
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
  }
`;
const BorderRadiusBackground = styled.div`
  width: 428px;
  height: 428px;
  bottom: 441px;
  opacity: 0.09;
  box-sizing: border-box;
  position: absolute;
  left: 449px;
  border-radius: 50%;
  border: 19px solid white;

  @media (max-width: 1280px) {
    width: 206px;
    height: 206px;
    top: -4px;
    border-width: 5px;
    right: -43px;
    left: unset;
  }
`;
const BackgroundMainLayout = styled(MainLayout)`
  height: 100%;
  position: relative;

  > img:nth-child(2) {
    position: absolute;
    bottom: 83px;
    border-radius: 50%;
  }

  @media (max-width: 1280px) {
    max-width: 400px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    > img:nth-child(2) {
      width: 305px;
      height: 305px;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
const BackgrondPosition = styled.div`
  position: absolute;
  height: 100%;

  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`;

const WelcomSection = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const showPage = useCallback(() => {
    navigate("/order");
    queryClient.setQueryData("profile", {
      point: 0,
    });
  }, [navigate, queryClient]);

  return (
    <Container>
      <BackgrondPosition>
        <BackgroundMainLayout>
          <FilledBackground />
          <img src={iconBackground} />
        </BackgroundMainLayout>
      </BackgrondPosition>
      <Header>
        <img src={logo} />
        <div>
          <Link to="/login">로그인</Link>
          <Link to="/register">회원가입</Link>
        </div>
      </Header>
      <WelcomLayout>
        <MainContentContainer>
          <div>
            <MainContentHeaderTitle>
              제이플러스를 통해{"\n"}
              마케팅을 시작해보세요!
            </MainContentHeaderTitle>
            <MainContentHeaderDescription>
              업계 최저가로 이용자가 24시간 원활한 상담과{"\n"}시원한 서비스를
              제공받을 수 있습니다.{"\n"}제이플러스로 당신의 인스타그램에 날개를
              달아보세요!
            </MainContentHeaderDescription>
            <AroundButton onClick={showPage}>둘러보기</AroundButton>
          </div>
        </MainContentContainer>
      </WelcomLayout>
    </Container>
  );
};

export default WelcomSection;
