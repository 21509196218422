import { FC } from "react";
import styled from "styled-components";
import { ClassName } from "../GlobalStyles";

const Container = styled.div`
  padding: 20px 40px;
  border-radius: 14px;

  @media (max-width: 880px) {
    padding: 20px 10px;
  }
`;

const NoticeTitle = styled.div`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
`;
const NoticeList = styled.div`
  height: 120px;
  overflow: auto;
  margin-top: 30px;

  > div {
    font-size: 14px;
    padding: 0 20px;
  }
  > div + div {
    margin-top: 3px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eeeeee;
    padding: 2px;
    border-radius: 4px;
    background-clip: content-box;
    border: 2px solid #eeeeee;
  }
`;
const BoldText = styled.div`
  padding: 3px !important;
  margin: 0 20px !important;
  margin-bottom: 10px !important;
  letter-spacing: -0.5px;
  background: rgb(255, 255, 0);
  color: black;
  margin-bottom: 5px;
  display: inline-block;
`;

const NoticePiece: FC = () => {
  return (
    <Container className={ClassName.shadow01}>
      <NoticeTitle>공지사항</NoticeTitle>

      <NoticeList>
        <BoldText>
          광고대행사를 위한 페이지 분양, API연동, 가격혜택을 지원하고 있습니다.
          ( 채널문의 )
        </BoldText>
        <BoldText>
          [EVENT] 카톡 채널 친구추가시 1,000포인트 무료 지급 이벤트
        </BoldText>

        <div>[230412] 인스타그램 리그램 상품 추가.</div>
        <div>[230103] 유튜브 해외 조회수 상품 추가.</div>
        <div>[230107] 대행사 관련 API_KEY 기능 추가.</div>
        <div>[220811] 유튜브 국내/해외 관련 서비스 추가.</div>
        <div>[220707] 페이스북 국내/해외 관련 서비스 추가.</div>
        <div>[220701] 인스타그램 대행사 관련 서비스 추가.</div>
        <div>[220630] 인스타그램 해외/국내 최적화.</div>
        <div>[220621] 인스타그램 해외 관련 서비스 추가.</div>
        <div>[220508] 인스타그램 국내 관련 서비스 추가.</div>
        <div>[220814] 전체 서비스 오류 확인 및 속도 향상.</div>
        <div>[220604] 주문하기 UI/UX 개선 및 기능 추가.</div>
        <div>[220504] 주문내역 UI/UX 개선 및 기능 추가.</div>
        <div>[211207] 주문 옵션들이 추가되었습니다.</div>
        <div>[210927] 사이트 이용을 가능하게 하는 UI/UX가 개선되었습니다.</div>
      </NoticeList>
    </Container>
  );
};

export default NoticePiece;
