import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { FormButton, FormInput } from "../components/styles";
import WidthModeNotify from "../components/WidthModeNotify";
import useIsMobile from "../hooks/useIsMobile";
import useOrderHistory from "../hooks/useOrderHistory";
import useServices from "../hooks/useService";
import { hideLoading, showLoading } from "../Loading";
import {
  dateFormat,
  getInstaLink,
  krwFormat,
  orderStartCountFormat,
  orderStatusFormat,
} from "../utils";

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
`;

const CategoryWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 880px) {
    margin-top: 10px;
    gap: 4px;
  }
`;
const CategoryItem = styled.div`
  padding: 7px 15px;
  background: #f6f6f6;
  border-radius: 14px;
  font-size: 12px;
  cursor: pointer;

  & + & {
    margin-left: 4px;
  }

  @media (max-width: 880px) {
    font-size: 8px;
    padding: 5px 10px;
    margin: 0 !important;
  }
`;
const TableItem = styled.div`
  border: 1px solid #d2d2d8;
  flex: 1;
  padding: 7px 15px;
  white-space: pre-wrap;
  word-break: break-all;

  font-size: 13px;
  box-sizing: border-box;
  margin: -0.5px;
  color: #333;

  &:nth-child(1) {
    border-left: none;
  }
  &:nth-child(7) {
    border-right: none;
  }
`;
const TableHeader = styled.div`
  background: #f6f6f6;
  display: flex;

  > div {
    font-weight: bold;
  }
`;
const TableBody = styled.div``;
const TableRow = styled.div`
  display: flex;
`;
const TableWrap = styled.div`
  margin-top: 15px;
  min-width: 1000px;
`;
const OverFlowHidden = styled.div`
  overflow: auto;
`;
const SearchInput = styled(FormInput)`
  height: unset;
  padding: 7px 15px;
  font-size: 12px;
  width: 150px;

  @media (max-width: 880px) {
    font-size: 8px;
    padding: 5px 10px;
  }
`;
const ConsoleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1030px) {
    display: block;
  }
`;
const SearchRight = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    margin-top: 10px;
  }
`;
const SearchButton = styled(FormButton)`
  font-size: 12px;
  width: fit-content;
  margin: 0;
  margin-left: 10px;
  padding: 7px 15px;

  @media (max-width: 880px) {
    font-size: 8px;
    padding: 5px 10px;
  }
`;
const OrderHistoryPage: FC = () => {
  const [category, setCategory] = useState<string>("all");
  const [keyword, setKeyword] = useState<string>("");
  const { data: services } = useServices({});
  const { data: orderHistory = [] } = useOrderHistory({
    onSuccess: () => {
      hideLoading();
    },
    onError: () => {
      hideLoading();
    },
  });
  useEffect(() => {
    showLoading();
  }, []);

  const changeKeyword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  }, []);

  const changeCategoryCallback = useCallback((value: string) => {
    return () => setCategory(value);
  }, []);

  const filteredHistory = useMemo(() => {
    if (category === "all") return orderHistory;

    return orderHistory.filter((history) => history.status === category);
  }, [orderHistory, category]);

  const keywordHistory = useMemo(() => {
    return filteredHistory.filter((history) =>
      String(history.orderId).includes(keyword)
    );
  }, [filteredHistory, keyword]);
  return (
    <>
      <WidthModeNotify />
      <Container>
        <ConsoleWrapper>
          <CategoryWrap>
            <CategoryItem onClick={changeCategoryCallback("all")}>
              전체
            </CategoryItem>
            <CategoryItem onClick={changeCategoryCallback("PENDING")}>
              주문접수
            </CategoryItem>
            <CategoryItem onClick={changeCategoryCallback("IN PROGRESS")}>
              주문준비중 또는 가동중
            </CategoryItem>
            <CategoryItem onClick={changeCategoryCallback("COMPLETED")}>
              주문완료
            </CategoryItem>
            <CategoryItem onClick={changeCategoryCallback("PARTIAL")}>
              주문실패(작업되지 않은 만큼 환불)
            </CategoryItem>
            <CategoryItem onClick={changeCategoryCallback("PROCESSING")}>
              주문대기
            </CategoryItem>
            <CategoryItem onClick={changeCategoryCallback("CANCELED")}>
              주문취소 및 전액 환불
            </CategoryItem>
            <CategoryItem onClick={changeCategoryCallback("ZOG8_CANCELED")}>
              주문중 비공개 변경(환불불가)
            </CategoryItem>
          </CategoryWrap>
          <SearchRight>
            <SearchInput
              onChange={changeKeyword}
              value={keyword}
              placeholder="주문번호"
            />
            <SearchButton>검색</SearchButton>
          </SearchRight>
        </ConsoleWrapper>

        <OverFlowHidden>
          <TableWrap>
            <TableHeader>
              <TableItem>주문번호</TableItem>
              <TableItem>주문일자</TableItem>
              <TableItem>주문상품</TableItem>
              <TableItem>주문링크</TableItem>
              <TableItem>사용금액(원)</TableItem>
              <TableItem>주문수량</TableItem>
              <TableItem>주문상태</TableItem>
              <TableItem>주문 전 수량</TableItem>
              <TableItem>남은수량</TableItem>
            </TableHeader>
            <TableBody>
              {keywordHistory.map((order) => {
                return (
                  <TableRow key={order.id}>
                    <TableItem>{order.id}</TableItem>
                    <TableItem> {dateFormat(order.createdAt)}</TableItem>
                    <TableItem>
                      {
                        services?.find(
                          (service) => service.id === order.serviceId
                        )?.text
                      }
                    </TableItem>
                    <TableItem>
                      <a target="_blank" href={getInstaLink(order.link)}>
                        {order.link}
                      </a>
                    </TableItem>
                    <TableItem>{krwFormat(order.point)}</TableItem>
                    <TableItem>{krwFormat(order.amount)}</TableItem>
                    <TableItem>{orderStatusFormat(order.status)}</TableItem>
                    <TableItem>
                      {orderStartCountFormat(order.startCount)}
                    </TableItem>
                    <TableItem>{order.remains}</TableItem>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableWrap>
        </OverFlowHidden>
      </Container>
    </>
  );
};

export default OrderHistoryPage;
