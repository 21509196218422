import AdvantageSection from "./main/AdvantageSection";
import BannerSection from "./main/BannerSection";
import ReviewSection from "./main/ReviewSection";
import WelcomSection from "./main/WelcomSection";

const MainPage = () => {
  return (
    <>
      <WelcomSection />
      <AdvantageSection />
      <ReviewSection />
      <BannerSection />
    </>
  );
};

export default MainPage;
